<script setup></script>
<template>
  <p
    class="text-white d-flex w-100 justify-content-center align-items-center fs-3 fw-bold"
  >
    Hollywood Actors Lists
  </p>
  <form
    class="w-100 p-d d-flex flex-column align-items-center"
    @submit.prevent="HandleSubmit"
  >
    <div class="form-control real-black border-0">
      <label for="" class="form-label text-white d-flex p-1 w-100 fw-bold"
        >Cari data actor disini</label
      >
      <input
        v-model="SearchText"
        type="text"
        class="form-control rounded-pill"
        placeholder="Cari Data Aktor Hollywood disini...."
      />
    </div>
  </form>

  <p
    class="text-white d-flex w-100 justify-content-center align-items-center fs-6 fw-light flex-column"
  >
    ini merupakan mockup pengggunaan vuejs untuk proses recruitment
    <b class="fw-bold fs-3">Sociolla</b> <br />Menggunakan Vue 3 dan Axios
    <br />
  </p>

  <div class="container-fluid p-2">
    <div
      class="mt-3 p-2 d-flex flex-column justify-content-center align-items-center"
      :class="[HasData ? 'd-none' : 'd-block']"
    >
      <div class="not-found-img-container">
        <iframe
          src="https://giphy.com/embed/FUKCPzVj0GGrCsdsmP"
          width="480"
          height="331"
          style=""
          frameBorder="0"
          class="giphy-embed"
        ></iframe>
        <p>
          <a
            href="https://giphy.com/gifs/colibridigital-colibrito-colibr-FUKCPzVj0GGrCsdsmP"
            >via GIPHY</a
          >
        </p>
        <!-- <img src="" alt="" srcset="" /> -->
      </div>
      <div
        class="w-100 p-2 d-flex justify-content-center align-items-center text-white fw-bold fs-5"
      >
        Uppss.. Sepertinya data Aktor yang anda cari tidak tersedia
      </div>
    </div>
    <div class="CardContainer">
      <div
        class="card p-2 cursor-pointer"
        v-for="actor in Actors"
        :key="actor.id"
      >
        <div class="w-100 h-50 overflow-hidden">
          <img :src="actor.image" alt="" srcset="" class="w-100 h-auto" />
        </div>

        <p class="fw-bold">{{ actor.name }}</p>
        <p>
          {{ actor.birth_year }}-{{
            actor.death_year ? actor.death_year : "now"
          }}
        </p>
        <p class="fw-bold">Reknown Movies</p>
        <ul class="list-decoration-dotted d-flex flex-column">
          <li v-for="(movie, index) in actor.known_for" :key="index">
            {{ movie }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped>
.CardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
}
.card {
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .CardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
  }
}
</style>
<script>
import axios from "axios";
import { ref } from "vue";
const SearchText = ref("");
export default {
  data() {
    return {
      Actors: [],
      SearchText: "",
      HasData: ref(true),
    };
  },
  mounted() {
    axios.get("https://freetestapi.com/api/v1/actors").then((response) => {
      this.Actors = response.data;
    });
  },
  methods: {
    SubmitEvent(e) {
      event.preventDefault();
      console.log(e);
    },
    sampleEvents() {
      console.log("I am Clicked");
      alert("I am clicked");
    },
    HandleSubmit() {
      console.log("Try To submit form !!!!");
      console.log(this.SearchText);
      this.RetrieveSpecificActors();
    },
    RetrieveSpecificActors() {
      return axios
        .get("https://freetestapi.com/api/v1/actors?search=" + this.SearchText)
        .then((response) => {
          console.log("response...", response.data);
          this.Actors = response.data;
          if (this.Actors.length < 1) {
            this.HasData = false;
          } else {
            this.HasData = true;
          }
        });
    },
    submit: function (e) {
      e.preventDefault();
      console.log("Try To submit form !!!!");
      console.log(this.SearchText, "...Search Text");
    },
  },
};
</script>
